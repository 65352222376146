<template>
    <div class="radius-anchor max-w-xl mx-auto ccss--area-selection">
        <div class="mb-5">
            <h2 class="text-2xl leading-9 tracking-tight font-extrabold sm:text-4xl sm:leading-10 text-center">
                {{ $t('tables.areas-selection.title') }}
            </h2>
        </div>

        <div class="method-tiles">
            <div v-for="(area, index) in areas" :key="index"
                 class="method-outer"
            >
                <div @click="areaSelect(area)" class="method radius-anchor area">
                        <span class="img-wrapper">
                        </span>
                    <div class="text-center">
                        <div class="">{{ area.attributes.name }}</div>
                        <!--                            <p> {{area.attributes.description}} </p>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as _api from '../../lib/api';
import * as _state from "../../lib/state";

export default {
    name: 'table.area.selection',
    props: ['location_slug'],
    components: {},
    data () {
        return {
            areas: [],
        };
    },
    mounted () {
        this.getAreas();
    },

    methods: {
        // todo: needs testing
        async getAreas () {
            this.areas = await _api.get('/location/areas-selection');
        },

        areaSelect (area) {
            this.$router.push('/' + this.location_slug + '/areas/' + area.id + '/selection/table');
        },
    },
    computed: {

        location () {
            return _state.get('location/getLocation');
        },
    },
};
</script>
